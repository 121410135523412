<template>
  <div class="tw-flex tw-items-center tw-w-full tw-py-0.5">
    <!-- Outer progress bar container -->
    <div class="tw-relative tw-w-full tw-bg-gray-200 tw-rounded-full tw-overflow-hidden tw-flex tw-items-center tw-justify-center">
      <!-- Inner progress bar (fill) -->
      <div
        class="tw-bg-primary tw-absolute tw-left-0 tw-top-0 tw-h-full tw-transition-all tw-duration-300"
        :style="{ width: progressPercentage + '%' }"
      ></div>

      <!-- Progress text (inside the bar) -->
      <span class="tw-relative tw-text-gray-800 tw-font-medium">
        {{ label }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    mode: {
      type: String,
      default: 'fraction',
      required: false
    }
  },
  computed: {
    progressPercentage () {
      return this.total > 0 ? Math.round((this.progress / this.total) * 100) : 0
    },
    label () {
      if (this.mode === 'percentage') {
        return `${this.progressPercentage}%`
      } else {
        return `${this.progress}/${this.total}`
      }
    }
  }
}
</script>
