<template>
  <div>
    <h2 class="tw-mt-0 tw-mb-4">Preview</h2>
    <iframe
      id="pdf_frame"
      type="application/pdf"
      :src="pdfUrl"
      class="tw-w-full tw-h-full tw-min-h-[69vh]"
    />
    <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-gap-x-2 tw-justify-end">
      <FormulateInput
        :disabled="isLoading"
        type="button"
        :input-class="['tw-w-full tw-bg-gray-500']"
        @click="goBack"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
      <FormulateInput
        type="button"
        :disabled="isLoading"
        title="Rapport opslaan"
        :input-class="['tw-w-full']"
        @click="saveEstimationReport"
      >
        <i :class="['fas tw-mr-2', isLoading ? 'fa-spinner-third fa-spin' : 'fa-file-save']" />
        Rapport opslaan
      </FormulateInput>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PropertyEstimateReportPreviewModalStep5',
  props: {
    pdfUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    goBack () {
      this.$emit('back')
    },
    saveEstimationReport () {
      this.isLoading = true
      this.$emit('saveEstimationReport')
    },
    stopLoading () {
      this.isLoading = false
    }
  }
}
</script>
