<template>
  <div>
    <h2 class="tw-mt-0 tw-mb-4">Waardebeïnvloedende factoren (constructie)</h2>
    <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-2 tw-gap-0.5 tw-text-sm">
      <dt>Vetusiteit ouderdom</dt>
      <dd>{{ estimateReportData.vetusity_age }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Vetusiteit onderhoud</dt>
      <dd>{{ estimateReportData.vetusity_maintenance }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Vetusiteit gebruik</dt>
      <dd>{{ estimateReportData.vetusity_usage }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Energetische factor</dt>
      <dd>{{ estimateReportData.energetic_factor }}</dd>
    </dl>
    <hr class="tw-border-1 tw-border-gray-cc tw-my-4"/>
    <h2 class="tw-mt-0 tw-mb-4">Waardeberekening</h2>
    <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-2 tw-gap-0.5 tw-text-sm">
      <dt></dt>
      <dd>
        <div class="tw-grid tw-grid-cols-2 tw-gap-0.5">
          <span class="tw-font-bold">Min</span>
          <span class="tw-font-bold">Max</span>
        </div>
      </dd>
      <dt>Totaal perceel</dt>
      <dd>
        <div class="tw-grid tw-grid-cols-2 tw-gap-0.5">
          <span>{{ estimateReportData.plot_total.min }}</span>
          <span>{{ estimateReportData.plot_total.max }}</span>
        </div>
      </dd>
      <dt class="tw-mt-1 md:tw-mt-0">Totaal constructie</dt>
      <dd>
        <div class="tw-grid tw-grid-cols-2 tw-gap-0.5">
          <span>{{ estimateReportData.construction_total.min }}</span>
          <span>{{ estimateReportData.construction_total.max }}</span>
        </div>
      </dd>
      <dt class="tw-mt-1 md:tw-mt-0">Totaal diversen</dt>
      <dd>
        <div class="tw-grid tw-grid-cols-2 tw-gap-0.5">
          <span>{{ estimateReportData.miscellaneous_total.min }}</span>
          <span>{{ estimateReportData.miscellaneous_total.max }}</span>
        </div>
      </dd>
      <dt class="tw-mt-1 md:tw-mt-0">Totaal</dt>
      <dd>
        <div class="tw-grid tw-grid-cols-2 tw-gap-0.5">
          <span class="tw-font-bold">{{ estimateReportData.value_total.min }}</span>
          <span class="tw-font-bold">{{ estimateReportData.value_total.max }}</span>
        </div>
      </dd>
    </dl>
    <hr class="tw-border-1 tw-border-gray-cc tw-my-4"/>
    <dl class="tw-mt-2.5 tw-mb-0 tw-grid md:tw-grid-cols-2 tw-gap-0.5 tw-text-sm">
      <dt>Intrinsieke waarde</dt>
      <dd>{{ estimateReportData.value_intrinsic }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Adviesvraagprijs (optioneel)</dt>
      <dd>{{ estimateReportData.value_advice }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Pre-sale-/powersale-instelprijs (optioneel)</dt>
      <dd>{{ estimateReportData.value_presale }}</dd>
    </dl>
    <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-gap-x-2 tw-justify-end">
      <FormulateInput
        type="button"
        :input-class="['tw-w-full tw-bg-gray-500']"
        @click="goBack"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
      <FormulateInput
        type="button"
        title="Volgende stap"
        :input-class="['tw-w-full']"
        @click="goForward"
      >
        <i class="fas tw-mr-2 fa-arrow-right" />
        Volgende <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PropertyEstimateReportPreviewModalStep3',
  props: {
    estimateReportData: {
      type: Object,
      required: true
    }
  },
  computed: {},
  methods: {
    goForward () {
      this.$emit('next')
    },
    goBack () {
      this.$emit('back')
    }
  }
}
</script>
