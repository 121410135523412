<template>
  <div>
    <h2 class="tw-mt-0 tw-mb-4">Compleetheid van de kenmerken</h2>
    <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-2 tw-gap-0.5 tw-text-sm">
      <template v-for="(feature, index) in features">
        <!-- Title -->
        <dt :key="`title-${index}`">
          {{ feature.title }}
        </dt>

        <!-- Progress Bar -->
        <dd :key="`values-${index}`">
          <ProgressBar
            :total="Number(feature.values.field_count) || 1"
            :progress="Number(feature.values.fields_completed) || 0"
            :mode="'percentage'"
          />
        </dd>
      </template>
    </dl>
    <hr class="tw-border-1 tw-border-gray-cc tw-my-4"/>
    <h2 class="tw-mt-0 tw-mb-4">Foto's (minimum 1)</h2>
    <EntityPictureCarousel :pictures="pictures" />
    <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-gap-x-2 tw-justify-end">
      <FormulateInput
        type="button"
        :input-class="['tw-w-full tw-bg-gray-500']"
        @click="goBack"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
      <FormulateInput
        type="button"
        title="Volgende stap"
        :input-class="['tw-w-full']"
        @click="goForward"
      >
        <i class="fas tw-mr-2 fa-arrow-right" />
        Volgende <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
    </div>
  </div>
</template>
<script>
import ProgressBar from '@/components/ProgressBar'
import EntityPictureCarousel from '@/components/properties/EntityPictureCarousel'

export default {
  name: 'PropertyEstimateReportPreviewModalStep2',
  components: { EntityPictureCarousel, ProgressBar },
  props: {
    estimateReportData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      pictures: this.estimateReportData.pictures.length <= 8 ? this.estimateReportData.pictures : this.estimateReportData.pictures.slice(0, 8)
    }
  },
  computed: {
    features () {
      return [
        {
          title: 'Energie',
          values: this.estimateReportData.preview_data.energy_fields
        },
        {
          title: 'Verwarming',
          values: this.estimateReportData.preview_data.heating_fields
        },
        {
          title: 'Telecom',
          values: this.estimateReportData.preview_data.telecom_fields
        },
        {
          title: 'Water en afvoer',
          values: this.estimateReportData.preview_data.water_fields
        },
        {
          title: 'Constructie',
          values: this.estimateReportData.preview_data.construction_fields
        },
        {
          title: "Extra's",
          values: this.estimateReportData.preview_data.extra_fields
        },
        {
          title: 'Bedrijfsvastgoed',
          values: this.estimateReportData.preview_data.business_fields
        },
        {
          title: 'Kadaster',
          values: this.estimateReportData.preview_data.cadaster_fields
        },
        {
          title: 'Onroerende voorheffing',
          values: this.estimateReportData.preview_data.property_tax_fields
        },
        {
          title: 'Gewestinfo',
          values: this.estimateReportData.preview_data.region_fields
        },
        {
          title: 'Onroerend erfgoed',
          values: this.estimateReportData.preview_data.heritage_fields
        },
        {
          title: 'Recht van voorkoop',
          values: this.estimateReportData.preview_data.preemption_fields
        },
        {
          title: 'Overstromingsgevaar',
          values: this.estimateReportData.preview_data.water_policy_fields
        },
        {
          title: 'Extra info',
          values: this.estimateReportData.preview_data.extra_info_fields
        }
      ]
    }
  },
  methods: {
    goForward () {
      this.$emit('next')
    },
    goBack () {
      this.$emit('back')
    }
  }
}
</script>
