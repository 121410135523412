<template>
  <!-- This input always rounds up the value to the nearest integer in case of decimals, by default. -->
  <input
    ref="input"
    :value="formattedValue"
    v-bind="attributes"
    @blur="onBlur"
    @focus="onFocus"
    @change="onChange"
  />
</template>

<script>
export default {
  name: 'NumberMaskInput',
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    allowNegative: {
      type: Boolean,
      default: true
    },
    config: {
      // Extra config to pass to the Intl.NumberFormat constructor
      type: Object,
      default: () => {
        return {
          style: 'currency',
          currency: 'EUR'
        }
      }
    },
    postFormat: {
      // Function to run after the value has been formatted
      type: Function,
      default: (value) => value
    }
  },
  data () {
    return {
      focused: false
    }
  },
  computed: {
    attributes () {
      return {
        step: 1,
        lang: 'nl',
        // Above values can be overridden by the attributes
        ...this.$attrs,
        type: 'text'
      }
    },
    formattedValue () {
      if (!this.value && this.value !== 0) return null

      return this.focused
        ? Math.ceil(this.value) // If focused, just show the value without decimals
        : this.format(this.value) // If not focused, show the masked value
    },
    formatter () {
      // Cached formatter instance
      return new Intl.NumberFormat('nl-BE', {
        // Always round up with ceiling function to match the integer value.
        roundingMode: 'ceil',
        // Since we don't want to show decimals by default,
        // we set the minimumFractionDigits and maximumFractionDigits to 0
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        ...this.config
      })
    }
  },
  methods: {
    format (value) {
      /**
       * Converts a string value to a numeric value by removing all non-digit characters except decimal points and minus signs.
       * This is useful for currency/meter input validation where we want to strip out the symbols and formatting characters.
       * @param {string|number} value - The input value to be converted
       * @returns {number} - The cleaned numeric value
       */
      const number = Number(String(value).replace(/[^\d.-]/g, ''))
      const formattedValue = this.formatter.format(number)
      return this.postFormat(formattedValue)
    },

    // We change the input type on blur and focus to do masking of the values.
    onBlur (event) {
      // If the input is invalid, maintain focus
      if (!event?.target?.validity?.valid) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
        return
      }

      // If the input is valid, we can show the formatted value
      this.focused = false
      event.target.type = 'text'
      this.$emit('blur', event)
    },
    onFocus (event) {
      this.focused = true
      event.target.type = 'number'
      this.$emit('focus', event)

      this.$nextTick(() => {
        // To keep the default behavior of selecting the input value on focus
        event.target.select()
      })
    },

    onChange (event) {
      if (!event?.target?.validity?.valid) return false

      const value = event.target.value
      const newValue = !value ? value : Math.ceil(Number(value.replace(/[^\d.-]/g, '')))
      const previousValue = this.value ? Math.ceil(this.value) : this.value

      /*
        Only emit the change event if the value has actually changed. this.value is the previous saved value.
        When data is received from the API, it always is a string with decimals, so we need to compare correctly.
      */
      if (previousValue !== newValue) this.$emit('change', newValue)
      return event
    }
  }
}
</script>
