<template>
  <div>
    <div class="tw-flex tw-overflow-x-auto tw-gap-4">
      <div
        v-for="picture in pictures" :key="picture.id"
        class="tw-shadow-md tw-rounded-md tw-bg-white tw-overflow-hidden tw-min-h-full tw-w-auto tw-flex-shrink-0 tw-max-w-[50%]"
      >
        <img
          :src="picture.url || noImageAvailable"
          :alt="picture.id"
          loading="lazy"
          class="tw-w-full tw-h-full tw-max-h-[150px] tw-object-cover"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EntityPictureCarousel',
  props: {
    pictures: {
      type: Array
    }
  },
  computed: {
    noImageAvailable () {
      return require('@/assets/img/property_dummy.gif')
    }
  }
}
</script>
