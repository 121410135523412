<template>
  <div>
    <h2 class="tw-mt-0 tw-mb-4">Overzicht</h2>
    <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-2 tw-gap-0.5 tw-text-sm">
      <dt>Adres te schatten vastgoed</dt>
      <dd v-html="estimateReportData.preview_data.property_address"></dd>
      <dt class="tw-mt-1 md:tw-mt-0">Eigenaar</dt>
      <dd v-html="ownerAddress"></dd>
    </dl>
    <hr class="tw-border-1 tw-border-gray-cc tw-my-4"/>
    <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-2 tw-gap-0.5 tw-text-sm">
      <dt>Doel van de schatting</dt>
      <dd>{{ estimateReportData.reason }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Datum plaatsbezoek</dt>
      <dd>{{ estimateReportData.intake_visit_date }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Datum opmaak schattingsrapport</dt>
      <dd>{{ estimateReportData.estimation_report_date }}</dd>
    </dl>
    <hr class="tw-border-1 tw-border-gray-cc tw-my-4"/>
    <dl class="tw-my-2.5 tw-grid md:tw-grid-cols-2 tw-gap-0.5 tw-text-sm">
      <dt>Type vastgoed</dt>
      <dd>{{ estimateReportData.property_type }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Bewoonbare oppervlakte</dt>
      <dd>{{ estimateReportData.technical.surface_livable }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Perceeloppervlakte</dt>
      <dd>{{ estimateReportData.technical.surface_plot }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Bouwjaar</dt>
      <dd>{{ estimateReportData.technical.build_year }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Renovatiejaar</dt>
      <dd>{{ estimateReportData.technical.renovation_year }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Oriëntatie</dt>
      <dd>{{ estimateReportData.technical.orientation }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Slaapkamers</dt>
      <dd>{{ estimateReportData.number_of_bedrooms }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Aantal parkeerplaatsen</dt>
      <dd>{{ estimateReportData.number_of_parking_spots }}</dd>
      <dt class="tw-mt-1 md:tw-mt-0">Badkamers</dt>
      <dd>{{ estimateReportData.number_of_bathrooms }}</dd>
    </dl>
    <div class="md:tw-flex tw-gap-x-2 tw-justify-end">
      <FormulateInput
        type="button"
        title="Volgende stap"
        :input-class="['tw-w-full']"
        @click="goForward"
      >
        <i class="fas tw-mr-2 fa-arrow-right" />
        Volgende <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PropertyEstimateReportPreviewModalStep1',
  props: {
    estimateReportData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ownerAddress () {
      const owner = this.estimateReportData.owner
      return `${owner.name}<br>${owner.preview_address}`
    }
  },
  methods: {
    goForward () {
      this.$emit('next')
    }
  }
}
</script>
