<template>
  <FormulateForm
    v-model="values"
    name="estimationReportGenerateForm"
    @submit="generateEstimationReport"
  >
    <div>
      <h2 class="tw-mt-0 tw-mb-4">Kaarten</h2>
      <div class="tw-grid tw-grid-cols-2 tw-gap-3">
        <div v-for="map in maps" :key="map.title">
          <span class="tw-font-bold">{{map.title}}</span>
          <img v-if="map.url" :src="map.url" alt="map" />
          <p v-else>Er is geen kaart beschikbaar van dit type</p>
        </div>
      </div>
      <h2 class="tw-mt-4 tw-mb-4">Configuratie</h2>
      <FormulateInput
        type="checkbox"
        label="Factor tonen in tabel"
        name="show_factor"
      >
      </FormulateInput>
      <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-gap-x-2 tw-justify-end">
        <FormulateInput
          type="button"
          :disabled="isGenerating"
          :input-class="['tw-w-full tw-bg-gray-500']"
          @click="goBack"
        >
          <i class="fas tw-mr-2 fa-arrow-left" />
          Vorige <span class="tw-hidden md:tw-inline">stap</span>
        </FormulateInput>
        <FormulateInput
          type="submit"
          :disabled="isGenerating || !isEstimationReportDataComplete"
          title="Genereer rapport"
          :input-class="['tw-w-full']"
        >
          <i :class="['fas tw-mr-2', isGenerating ? 'fa-spinner-third fa-spin' : 'fa-file-upload']" />
          Genereren
        </FormulateInput>
      </div>
      <div class="tw-text-right tw-text-red-500" v-if="!isEstimationReportDataComplete">
        {{ incompleteEstimationReportDataMessage }}
      </div>
    </div>
  </FormulateForm>
</template>
<script>
export default {
  name: 'PropertyEstimateReportPreviewModalStep4',
  props: {
    estimateReportData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isGenerating: false,
      values: {
        show_factor: true
      }
    }
  },
  computed: {
    maps () {
      return [
        {
          title: 'Gewestplan',
          url: this.estimateReportData.maps.region_plan_map
        },
        {
          title: 'Watertoets',
          url: this.estimateReportData.maps.water_policy_map
        },
        {
          title: 'Voorkooprecht',
          url: this.estimateReportData.maps.preemption_map
        },
        {
          title: 'Onroerend erfgoed',
          url: this.estimateReportData.maps.heritage_map
        },
        {
          title: 'Inplantingsplan',
          url: this.estimateReportData.maps.location_plan_map
        }
      ]
    },
    isEstimationReportDataComplete () {
      return this.estimateReportData.pictures.length > 0 && Object.values(this.estimateReportData.maps).every(value => Boolean(value))
    },
    incompleteEstimationReportDataMessage () {
      let message = 'Kan niet genereren:'
      if (this.estimateReportData.pictures.length === 0) {
        message += ' Er zijn geen publieke fotos.'
      }
      if (!Object.values(this.estimateReportData.maps).every(value => Boolean(value))) {
        message += ' Eén of meerdere kaarten ontbreken.'
      }
      return message.length > 0 ? message : 'Genereer rapport'
    }
  },
  methods: {
    goBack () {
      this.$emit('back')
    },
    generateEstimationReport () {
      this.isGenerating = true
      this.$emit('generateEstimationReport', this.values)
    },
    stopGenerating () {
      this.isGenerating = false
    }
  }
}
</script>
